<template>
    <div>
        <CompContent :title="'查看' + (detail ? ' - ' + detail.title : '')">
            <template #operate>
                <Button type="info" @click="$refs.process_form.display(null, null, detail.id)">添加过程资料</Button>
            </template>
            <div class="detail-box" v-if="detail">
                <div class="info-box">
                    <div class="info-item">
                        <p class="name">议事主题：</p>
                        <p class="value">{{ detail.title }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">议题类型：</p>
                        <p class="value">{{ issueTypes[detail.issueType] || "未知" }}</p>
                    </div>
                    <div class="info-item" v-if="detail.processTime">
                        <p class="name">开展时间：</p>
                        <p class="value">{{ detail.processTime }}</p>
                    </div>
                    <div class="info-item" v-if="detail.processAddr">
                        <p class="name">开展地点：</p>
                        <p class="value">{{ detail.processAddr }}</p>
                    </div>
                    <div class="info-item" v-if="detail.participation">
                        <p class="name">基层协商参与度：</p>
                        <p class="value">{{ consultation[detail.participation] }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">详情：</p>
                        <p class="value" v-html="detail.content"></p>
                    </div>
                </div>

                <div class="flow-box">
                    <block v-if="detail.processVoList && detail.processVoList.length > 0">
                        <div class="flow-title">过程资料：</div>
                        <div class="item-box" v-for="(item, idx) in detail.processVoList" :key="item.id">
                            <div class="operate-box">
                                <div class="item edit" @click="$refs.process_form.display(item.id, item)">
                                    <Icon type="md-create" />
                                </div>
                                <div class="item remove" @click="onDeleteFlow(item.id, idx)">
                                    <Icon type="md-trash" />
                                </div>
                            </div>
                            <div class="deta-box">
                                <div class="deta">{{ $core.formatDate(new Date(item.startDate), "yyyy-MM-dd") }}</div>
                                <div class="time">{{ $core.formatDate(new Date(item.startDate), "hh:mm") }} - {{ $core.formatDate(new Date(item.endDate), "yyyy-MM-dd hh:mm") }}</div>
                            </div>
                            <div class="content-box">
                                <div class="title">{{ item.title }}</div>
                                <div class="participant" v-if="item.participate">参与人：{{ item.participate.join(", ") }}</div>
                                <div class="content" v-html="item.content"></div>
                                <div class="photo-box" v-if="item.processImg">
                                    <div class="item" v-for="(src, idx) in item.processImg" :key="idx" @click="$refs.preview.display(src, item.photos)" :style="{ 'background-image': 'url(' + src + ')' }"></div>
                                </div>
                                <div class="file-box" v-if="item.processFile">
                                    <div class="item" v-for="(src, idx) in item.processFile" :key="idx" @click="onDownloadFile(src, '文档' + (idx + 1))">文档 {{ idx + 1 }}，点击下载</div>
                                </div>
                            </div>
                        </div>
                    </block>
                    <div class="empty-box" v-else>
                        <Icon type="ios-information-circle-outline" size="20" color="#999" style="margin-right:10px" />
                        <span>暂无过程资料，可点击右上方进行添加。</span>
                    </div>
                </div>
            </div>
        </CompContent>

        <!-- 过程资料表单 -->
        <ProcessForm ref="process_form" @on-submit="getFlowInfo"></ProcessForm>
        <!-- 预览图片 -->
        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import Preview from "../../comment/components/Preview.vue"
import CompContent from "../../customform/components/CompContent.vue"
import Request from "../../jointly/utils/request"
import Utils from "../../jointly/utils/utils"
import FileUtil from "../../customform/utils/file"

import ProcessForm from "./ProcessForm.vue"

export default {
    components: {
        CompContent,
        ProcessForm,
        Preview,
    },

    data() {
        return {
            detail: null,
            issueTypes: {},
            consultation: {},
        }
    },

    async created() {
        await this.getDictData("ISSUE_TYPE", "issueTypes")
        await this.getDictData("ISSUE_CONSULTATION", "consultation")
        this.getFlowInfo()
    },

    methods: {
        getFlowInfo() {
            return Request.get("/gateway/syinfopublish/api/pc/chamber/getIssueFlowInfo", {
                issueId: this.$core.getUrlParam("id"),
            }).then(res => {
                Utils.each(res.processVoList, v => {
                    if (v.participate) v.participate = JSON.parse(v.participate)
                    if (v.processImg) v.processImg = JSON.parse(v.processImg)
                    if (v.processFile) v.processFile = JSON.parse(v.processFile)
                    if (v.startDate && v.endDate) {
                        v.date = [new Date(v.startDate), new Date(v.endDate)]
                    }
                })

                this.detail = res
            })
        },

        async getDictData(name, key) {
            const res = await Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: name,
                appCode: "",
            })

            const obj = {}

            Utils.each(res, v => {
                obj[v.dictKey] = v.dictValue
            })

            this[key] = obj
        },

        onDeleteFlow(id, idx) {
            this.$Modal.confirm({
                title: "提示",
                content: "当前删除操作将不可恢复，请谨慎操作，是否继续？",
                onOk: () => {
                    Request.post("/gateway/syinfopublish/api/pc/chamber/deleteProcess", {
                        id: id,
                    }).then(() => {
                        this.$Message.success({
                            content: "删除成功",
                            background: true,
                        })
                        // 移除
                        this.detail.processVoList.splice(idx, 1)
                    })
                },
            })
        },

        /** 下载文件 */
        onDownloadFile(url, title) {
            FileUtil.download(url, title)
        },
    },
}
</script>

<style lang="less">
.detail-box {
    display: flex;
    height: 100%;

    .info-box {
        padding: 20px;
        width: 400px;
        height: 100%;
        border-right: 1px solid #e3e3e3;
        flex-shrink: 0;

        .info-item {
            padding-top: 15px;
            display: flex;
            max-width: 500px;
            width: 100%;
            line-height: 20px;

            &.border {
                margin-top: 10px;
                border-top: 1px solid #f3f3f3;
            }

            .name {
                color: #666;
                font-size: 13px;
                flex-shrink: 0;
                white-space: nowrap;
            }

            .value {
                color: #333;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }

    .flow-box {
        padding: 30px 20px 20px 0;
        flex-grow: 1;

        .flow-title {
            padding: 0 20px 20px 30px;
            font-size: 16px;
            color: #333;
        }

        .item-box {
            position: relative;
            display: flex;
            padding-left: 30px;
            margin-bottom: 20px;

            .operate-box {
                position: absolute;
                top: 0;
                left: 4px;
                transition: all 0.2s;
                opacity: 0;

                .item {
                    cursor: pointer;
                    padding: 3px;
                    margin: 8px 0 4px 0;
                    font-size: 14px;
                    display: flex;
                    color: #fff;
                    border-radius: 3px;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

                    &.edit {
                        background: #38a41f;
                    }

                    &.remove {
                        background: #f56c6c;
                    }
                }
            }

            &:hover {
                .operate-box {
                    opacity: 1;
                }
            }

            .deta-box {
                flex-shrink: 0;
                margin-right: 20px;
                padding-right: 20px;
                border-right: 1px solid #eee;

                .deta {
                    font-size: 25px;
                    letter-spacing: 1px;
                    font-weight: 500;
                    color: #333;
                }

                .time {
                    font-size: 12px;
                    color: #999;
                }
            }

            .content-box {
                flex-grow: 1;
                overflow: hidden;

                .title {
                    display: flex;
                    align-items: center;
                    color: #333;
                    font-size: 20px;
                }

                .participant {
                    margin: 5px 0;
                    font-size: 12px;
                    color: #888;
                }

                .content {
                    margin-top: 5px;
                    word-wrap: break-word;
                    font-size: 14px;
                    color: #666;
                }

                .photo-box {
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;

                    .item {
                        cursor: pointer;
                        width: 150px;
                        height: 100px;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 1px solid #e3e3e3;
                        margin: 0 5px 5px 0;
                        background-position: center;
                        background-size: cover;
                    }
                }

                .file-box {
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;

                    .item {
                        cursor: pointer;
                        padding: 5px 10px;
                        border-radius: 4px;
                        overflow: hidden;
                        margin: 0 5px 5px 0;
                        background: #2faaf7;
                        color: #fff;
                        font-size: 12px;
                    }
                }
            }
        }

        .empty-box {
            padding: 80px 40px;
            text-align: center;
            color: #999;
            font-size: 16px;
        }
    }
}
</style>
